import store from '@/store'
import router from "@/router";


function leaving(e) {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "";
    if (!store.state.reserva_confirmada) {
        store.dispatch('common/send_stat', {
            pagina: router.currentRoute.path,
            token: store.state.auth.token_stat
        })
    }

}

const send_stats_mixin = {
    created: function () {
        window.addEventListener(
            "beforeunload",
            leaving
        );
    }
}

export default send_stats_mixin